import Homepage from './pages/HomePage';
import { Analytics } from '@vercel/analytics/react';
function App() {
    return (
        <>
            <Homepage />;
            <Analytics />
        </>
    );
}

export default App;
