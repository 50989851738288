import React, { useState } from 'react';
import Cat2 from '../resources/images/cat2.gif';
import Cat1 from '../resources/images/cat1.gif';
import Cat3 from '../resources/images/sadcat.gif';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

export default function Homepage() {
    let [fontSize, setFontSize] = useState(0);
    const fontSizesArray = ['text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl'];
    const reverseFontSizesArray = ['text-[10px]', 'text-xs', 'text-sm', 'text-base', 'text-lg'].reverse();
    const paddingXArray = ['px-6', 'px-7', 'px-8', 'px-9', 'px-10'];
    const reversePaddingXArray = ['px-6', 'px-5', 'px-4', 'px-3', 'px-2'];
    const paddingYArray = ['py-3', 'py-4', 'py-5', 'py-6', 'py-7'];
    const reversePaddingYArray = ['py-3', 'py-2', 'py-1', 'py-0', 'py-0'];
    function increaseButtonSizeAndFont() {
        if (fontSize < reverseFontSizesArray.length - 1) setFontSize(fontSize + 1);
    }

    const questionsArray = [
        'Will you be my girlfriend?',
        "I'm sorry I hurt your neck 😓",
        'I swear it was an accident 😶‍🌫️',
        'Pleaseeeeee 🥺',
        'Pretty please 🥺🥺🥺',
    ];
    const { width, height } = useWindowSize();
    const [clicked, setClicked] = useState(false);
    return (
        <div className='flex flex-col items-center justify-center min-h-dvh bg-nailpolishpink p-6'>
            <div className='w-[250px] h-[250px]'>
                <img
                    alt='Animated Cat'
                    className='w-full h-full object-contain'
                    src={clicked === 'yes' ? Cat1 : clicked ? Cat3 : Cat2}
                />
            </div>
            {clicked === 'yes' && (
                <p className='text-xl font-semibold text-white'>wow that was {fontSize !== 0 && 'not'} easy</p>
            )}
            <h1 className='text-3xl font-bold text-red-400 font-fantasy text-center'>{`${
                clicked === 'yes' ? "you're so fucking beautiful" : questionsArray[fontSize]
            } `}</h1>
            <div className='custom-row'>
                <button
                    className={`mt-3 ${clicked !== 'yes' ? paddingXArray[fontSize] : 'px-6'} ${
                        clicked !== 'yes' ? paddingYArray[fontSize] : 'py-2'
                    } ${
                        clicked !== 'yes' ? fontSizesArray[fontSize] : 'text-lg'
                    } transition-all duration-500 font-bold text-white bg-red-400 rounded-md shadow-md `}
                    onClick={() => {
                        setClicked('yes');
                    }}
                >
                    {clicked === 'yes' ? '🤍' : 'Yes'}
                </button>
                <button
                    style={{
                        display: clicked === 'yes' ? 'none' : 'inline',
                    }}
                    className={`mt-3 ${reversePaddingXArray[fontSize]} ${reversePaddingYArray[fontSize]} ${reverseFontSizesArray[fontSize]} transition-all font-bold text-red-400 bg-white border-1 border-red-400 rounded-md shadow-md `}
                    onClick={() => {
                        increaseButtonSizeAndFont();
                        setClicked(true);
                    }}
                >
                    No
                </button>
            </div>
            <Confetti width={width} height={height} recycle={false} initialVelocityY={5} run={clicked === 'yes'} />
        </div>
    );
}
